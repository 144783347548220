import * as React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const Card = styled.article`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  background: white;
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 100%;
  
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  }
`

const CardImage = styled.div`
  aspect-ratio: 16/9;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
`

const CardContent = styled.div`
  padding: 1.5rem;
`

const Title = styled.h2`
  margin: 0 0 0.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: #111;
`

const Date = styled.time`
  font-size: 0.875rem;
  color: #666;
  display: block;
  margin-bottom: 1rem;
`

const Excerpt = styled.p`
  color: #444;
  margin: 0 0 1rem;
  line-height: 1.6;
`

const ReadMore = styled.span`
  color: #111;
  font-weight: 500;
  font-size: 0.875rem;
  
  &:hover {
    text-decoration: underline;
  }
`

const Post = ({
  title,
  date,
  link,
  imageSrc = "https://images.unsplash.com/photo-1513569771920-c9e1d31714af?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fGJsYWNrfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60",
  excerpt,
}) => {
  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <Card>
        <CardImage src={imageSrc} />
        <CardContent>
          <Title>{title}</Title>
          <Date>{date}</Date>
          <Excerpt>{excerpt}</Excerpt>
          <ReadMore>Read More →</ReadMore>
        </CardContent>
      </Card>
    </Link>
  )
}

export default Post
